import { isLoggedIn } from '@commonstock/common/src/auth'
import GlobalFeed, { DEFAULT_FEED_PAGE_LIMIT, DEFAULT_PERIOD } from 'src/scopes/feed/GlobalFeed'
import createLayout from 'src/scopes/layout/createLayout'
import { Screen } from 'src/scopes/analytics/constants'
import { GetServerSideProps } from 'next'
import getPublicResource from 'src/utils/getPublicResource'
import {
  GlobalFeedOutput,
  GlobalFeedPeriodType,
  GlobalFeedSortType,
  GLOBAL_FEED_PATH
} from '@commonstock/common/src/api/feed'
import { ColumnSizeTypes } from 'src/scopes/layout/constants'
import { OxcartPage } from 'src/utils/oxcart'
import { usePageView } from 'src/scopes/analytics/events.v1'
import { useRouter } from 'next/router'

const columnSize = ColumnSizeTypes.Half

type Props = {
  publicTopFeed?: GlobalFeedOutput
  publicNewFeed?: GlobalFeedOutput
  period?: GlobalFeedPeriodType
}
const FeedScreen: OxcartPage<any> = ({ publicNewFeed, publicTopFeed, period }: Props) => {
  const { pathname } = useRouter()
  usePageView({
    page: Screen.Home,
    page_id: pathname === Screen.Home ? GlobalFeedSortType.TOP : GlobalFeedSortType.NEW
  })
  return (
    <GlobalFeed
      publicNewFeed={publicNewFeed}
      publicTopFeed={publicTopFeed}
      ssrPeriod={period}
      columnSize={columnSize}
    />
  )
}

FeedScreen.getLayout = createLayout({
  publicScreen: true,
  hasSingleColumn: () => isLoggedIn() && false,
  columnSize: () => columnSize
})
FeedScreen.screen = Screen.Home

export default FeedScreen

export const getServerSideProps: GetServerSideProps = async ({ query, req, res }) => {
  const start = new Date()
  // @NOTE: Changed from 30s to 10s to make feed updates more frequent, this will continue to update
  res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=86400')

  function getFeed(objParams: Record<string, string>) {
    const params = new URLSearchParams({ limit: `${DEFAULT_FEED_PAGE_LIMIT * 2}`, ...objParams }).toString()
    return getPublicResource<GlobalFeedOutput>(GLOBAL_FEED_PATH + `?${params}`)
  }

  const { period = DEFAULT_PERIOD } = query
  const [publicTopFeed, publicNewFeed] = await Promise.all([
    getFeed({ sort: GlobalFeedSortType.TOP, period: period.toString() }),
    getFeed({ sort: GlobalFeedSortType.NEW })
  ])

  const props: Props = { period: period.toString() as GlobalFeedPeriodType }
  if (publicTopFeed?.success) props.publicTopFeed = publicTopFeed.payload
  if (publicNewFeed.success) props.publicNewFeed = publicNewFeed.payload

  const end = new Date()
  console.info(`Page rendered`, { url: req.url, time: end.getTime() - start.getTime() })

  return { props }
}
